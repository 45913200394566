import { render, staticRenderFns } from "./easycash-indo.html?vue&type=template&id=36ca778d&scoped=true&"
import script from "./easycash-indo.js?vue&type=script&lang=js&"
export * from "./easycash-indo.js?vue&type=script&lang=js&"
import style0 from "./easycash-indo.scss?vue&type=style&index=0&id=36ca778d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ca778d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36ca778d')) {
      api.createRecord('36ca778d', component.options)
    } else {
      api.reload('36ca778d', component.options)
    }
    module.hot.accept("./easycash-indo.html?vue&type=template&id=36ca778d&scoped=true&", function () {
      api.rerender('36ca778d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/easycash-indo/index/easycash-indo.vue"
export default component.exports